import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined"
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { graphql, PageProps } from "gatsby"


interface ShopifyPageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const ShopifyPage : React.FC<PageProps<ShopifyPageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title: "Easy-to-use admin interface",
    },
    {
      title: "Responsive themes and customizable storefronts",
    },
    {
      title: "Built-in marketing and SEO tools",
    },
    {
      title: "Secure payment processing",
    },
    {
      title: "Mobile commerce optimization",
    },
    {
      title: "Multi-channel selling capabilities (social media, marketplaces)",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        " 7+ years of focused Shopify development. 200+ successful Shopify projects delivered",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        "Launched a custom Shopify Plus store for a fast-growing fashion brand, resulting in a 60% increase in conversion rates. Developed a unique subscription model for a specialty food company, leading to a 45% boost in recurring revenue",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        "Shopify Partner. Multiple team members with Shopify Expert certifications. Shopify Plus Service Partner",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <ImportantDevicesOutlinedIcon />,
      title: "Development",
      desc: " Custom Shopify theme development. App development and integration. Shopify Plus customizations. Headless commerce solutions",
    },
    {
      imgSrc: <AspectRatioOutlinedIcon />,
      title: "Migration",
      desc: "Seamless migration from other platforms to Shopify. Data migration and integrity assurance. Minimal downtime during transition",
    },
    {
      imgSrc: <GroupOutlinedIcon />,
      title: " Support and maintenance",
      desc: "Ongoing Shopify store management. Regular updates and security patches. Performance monitoring and enhancement.",
    },
    {
      imgSrc: <GroupWorkOutlinedIcon />,
      title: " Integration",
      desc: " Third-party app integrations. Custom API development. ERP, CRM, and inventory management system integrations.",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Store performance optimization. Conversion rate optimization (CRO). Search engine optimization (SEO).",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Proven track record of successful Shopify implementations. Expertise in both technical development and e-commerce strategy. Focus on driving measurable business results",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        " Dedicated Shopify certified developers and designers. E-commerce strategists and digital marketers. Experienced project managers.",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "In-depth discovery and strategy planning. Agile development with regular client touchpoints. Comprehensive testing across devices and browsers. Smooth launch and post-launch optimization",
    },
  ]
  const faqData = [
    {
      question: "Is Shopify suitable for my business size?",
      answer:
        "Shopify offers solutions for businesses of all sizes. From Shopify Basic for startups to Shopify Plus for enterprise-level companies, there's a plan that can accommodate your needs and scale with your growth.",
    },
    {
      question: "  Can Shopify handle high-volume sales?",
      answer:
        "Absolutely. Shopify, especially Shopify Plus, is built to handle high-volume sales and traffic spikes. It offers 99.99% uptime and can process thousands of transactions per minute, making it suitable for businesses of any size.",
    },
    {
      question: " How customizable is a Shopify store?",
      answer:
        "Shopify stores are highly customizable. While you can start with pre-built themes, our development team can create fully custom designs and functionalities to match your brand and specific business needs.",
    },
    {
      question: "Can you help with migrating my existing store to Shopify?",
      answer:
        "Yes, we specialize in seamless migrations to Shopify. Our process ensures that your products, customer data, and order history are transferred accurately, with minimal disruption to your business.",
    },
    {
      question:
        " What ongoing support do you provide after launching a Shopify store?",
      answer:
        "We offer comprehensive post-launch support, including regular maintenance, performance monitoring, security updates, and ongoing optimizations to ensure your store continues to perform at its best and grow with your business.",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title="Shopify Development and Optimization Experts"
          description="Maximize your Shopify store's potential with our expert development, optimization, and support services. 9+ years of experience."
        />
        <div data-aos="fade-up">
          <PageHeading
            title={title}
            description={description}
          />
        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Powerful Features</IHeading>
                </div>
                <div className="xl:w-2/4 lg:w-3/4" data-aos="fade-up">
                  <IParagraph>
                    Shopify caters to a wide range of businesses, from small
                    startups to large enterprises, across various industries
                    including retail, fashion, beauty, food and beverage, and
                    digital products.
                  </IParagraph>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                Our team brings years of specialized experience in Shopify
                development and optimization, helping businesses maximize their
                online potential through tailored e-commerce solutions.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph color="gray">
                      <ul className="py-4 space-y-3 list-disc">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us for Shopify</IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Supercharge Your E-commerce with Shopify Expertise"
          description="Ready to take your online store to the next level? Our Shopify experts are here to help you launch, grow, and optimize your e-commerce business for maximum success"
          cta="Boost Your Shopify Store"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}


export const query = graphql`
  query ShopifyPageQuery {
    mdx(
      fileAbsolutePath: { regex: "/(technologies)/" }
      slug: { regex: "/(technologies/shopify)/" }
    ) {
      excerpt(pruneLength: 1000)
      frontmatter {
        title
        iconName
        description
      }
    }
  }
`
export default ShopifyPage
